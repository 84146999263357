import "../css/containerInfo.less";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  AutoComplete,
  Button,
  Card,
  Col,
  Form,
  FormListFieldData,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { EyeOutlined, FolderFilled } from "@ant-design/icons";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

import {
  IClinicResultCaptureForm,
  ClinicalResultsDetailHandle,
} from "../../../app/models/clinicResults";
import { ItipoValorForm as ITipoValorForm } from "../../../app/models/parameter";
import { IProceedingForm } from "../../../app/models/Proceeding";
import {
  IRequest,
  IRequestStudy,
  RequestStudyValues,
} from "../../../app/models/request";
import { IOptions } from "../../../app/models/shared";
import { useStore } from "../../../app/stores/store";
import { parameters, status } from "../../../app/util/catalogs";
import { numberRules } from "../../../app/util/utils";
import StatusTable from "../content/StatusTable";
import StudyActions from "../content/StudyActions";
import TitleColumns from "../content/TitleColumns";
import { referenceValues, updateStatus } from "../utils";
import { ObservationModal } from "./ObservationModal";
import { toJS } from "mobx";
import ClinicalResultsHistory from "./ClinicalResultsHistory";
import alerts from "../../../app/util/alerts";

const { TextArea } = Input;
const { Text, Link } = Typography;

const columnTypes = [
  parameters.valueType.unaColumna,
  parameters.valueType.dosColumnas,
  parameters.valueType.tresColumnas,
  parameters.valueType.cuatroColumnas,
  parameters.valueType.cincoColumnas,
];

type ClinicalResultsDetailProps = {
  estudio: IRequestStudy;
  paciente: IProceedingForm;
  medico: string;
  claveMedico: string;
  solicitud: IRequest;
  estudioId: number;
  isMarked?: boolean;
  printing: boolean;
  showHeaderTable: boolean;
  activeBranch: string;
};
type ParameterHistoryInfo = {
  paciente: string;
  solicitud: string;
  claveParametro: string;
  nombreParametro: string;
  solicitudId: string;
  parametroId: string;
  estudioId: number;
  valorInicial: string;
  valorFinal: string;
  isAllHistory: boolean;
  expediente: string;
  expedienteId: string;
};

const ClinicalResultsDetail = forwardRef<
  ClinicalResultsDetailHandle,
  ClinicalResultsDetailProps
>(
  (
    {
      estudio,
      estudioId,
      claveMedico,
      paciente,
      isMarked,
      solicitud,
      showHeaderTable,
      activeBranch,
    },
    ref
  ) => {
    const {
      clinicResultsStore,
      requestStore,
      profileStore,
      optionStore,
      branchStore,
      modalStore,
    } = useStore();

    const {
      getRequestStudyById,
      updateStatusStudy,
      studies,
      updateResults,
      cancelResults,
      removeSelectedStudy,
      observationsSelected,
      setObservationsSelected,
      clearSelectedStudies,
    } = clinicResultsStore;
    const { openModal } = modalStore;
    const { allStudies } = requestStore;
    const [currentStudy, setCurrentStudy] = useState<IRequestStudy>(
      new RequestStudyValues()
    );
    const [exportGlucoseData, setExportGlucoseData] =
      useState<IClinicResultCaptureForm>();

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [envioManual, setEnvioManual] = useState(false);
    const [checkedPrint, setCheckedPrint] = useState(false);
    const [, setResultParam] = useState<any[]>([]);
    const [, setModalValues] = useState<any>();
    const [NewStatus, setNewStatus] = useState<number>(0);
    const { getById: getBranchById } = branchStore;
    const { profile } = profileStore;

    const [form] = Form.useForm();
    const resultValue = Form.useWatch(
      "parametros",
      form
    ) as IClinicResultCaptureForm[];
    const navigate = useNavigate();

    useEffect(() => {
      clearSelectedStudies();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {}, [resultValue]);

    const loadInit = async () => {
      const cStudy = await getRequestStudyById(estudio.id!);
      form.setFieldValue("clavePatologica", cStudy?.clavePatologica);
      setCurrentStudy(cStudy!);

      let captureResult = studies.find(
        (x) => x.solicitudEstudioId! === estudio.id
      );

      if (captureResult && captureResult.parametros) {
        captureResult.parametros = captureResult.parametros.map((x) => {
          const obj = {
            ...x,
            resultado:
              x.tipoValorId === "5" || x.tipoValorId === "6"
                ? x.resultado?.toString()?.split(",")
                : x.resultado,
            rango:
              (x.criticoMinimo === 0 && x.criticoMaximo === 0) ||
              !x.criticoMinimo ||
              !x.criticoMaximo
                ? false
                : x.criticoMinimo! >= parseFloat(x.resultado as string) ||
                  parseFloat(x.resultado as string) >= x.criticoMaximo!,
          };
          return obj;
        });
      }

      setResultParam(
        captureResult === undefined ? [] : captureResult.parametros
      );
      setExportGlucoseData(
        captureResult?.parametros.find((x) => x.estudioId === 631)
      );
      form.setFieldValue("parametros", captureResult?.parametros);
    };

    useEffect(() => {
      loadInit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studies, estudio, estudioId]);
    const filterOptions = (inputValue: any, option: any) => {
      if (!option || !option.value) {
        return false;
      }
      return (
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      );
    };
    const onFinish = async (newValuesForm: any, enviarManual: boolean) => {
      let labResults: IClinicResultCaptureForm[] = newValuesForm.parametros;
      console.log("labcaptureresults", toJS(newValuesForm));

      let success = false;

      labResults = labResults.map((x) => {
        const obj = {
          ...x,
          sucursalActiva: activeBranch,
          resultado: Array.isArray(x.resultado)
            ? x.resultado.join()
            : x.resultado,
        };
        return obj;
      });

      success = await updateResults(
        {
          cliniciResultCaptureForm: labResults,
          isPatological: currentStudy.departamentoId === 3,
          clavePatologica: newValuesForm.clavePatologica,
        },
        solicitud.expedienteId,
        enviarManual
      );

      return success;
      return true;
    };

    const cancelation = async (estado: number) => {
      await updateStatusStudy(currentStudy.id!, estado);
      if (estado === status.requestStudy.solicitado) {
        return cancelResults(currentStudy.id!);
      }
    };

    const onSubmit = async (
      esCancelacion: boolean,
      currentStudy: IRequestStudy,
      enviarManual?: boolean
    ) => {
      if (!esCancelacion) {
        try {
          await form.validateFields();
        } catch (error) {
          return;
        }
      }

      if (
        currentStudy.estatusId !== status.requestStudy.solicitado &&
        currentStudy.estatusId !== status.requestStudy.capturado &&
        currentStudy.estatusId !== status.requestStudy.validado
      ) {
        setLoading(true);
      } else {
        if (updating) {
          return;
        }
        setUpdating(true);
      }

      if (
        currentStudy.estatusId === status.requestStudy.capturado &&
        !esCancelacion
      ) {
        const parameters = form.getFieldValue("parametros");
        console.log("parameters", toJS(parameters));

        const isSomeRequieredEmpty = parameters.filter(
          (x: IClinicResultCaptureForm) =>
            x.requerido &&
            (x.resultado === undefined ||
              x.resultado === null ||
              x.resultado === "")
        );

        if (!!isSomeRequieredEmpty.length) {
          setLoading(false);
          setUpdating(false);

          notification.config({
            rtl: true,
          });

          notification.warning({
            message: "PARAMETROS REQUERIDOS",
            description:
              "Capturar los parametros requeridos.\n" +
              isSomeRequieredEmpty
                .map((x: IClinicResultCaptureForm) => x.nombre)
                .join(",\n "),
            placement: "bottomRight",
            duration: 5,
          });

          return;
        }
      }
      try {
        const isUpdated = await updateStatus(
          esCancelacion,
          currentStudy,
          updateStatusStudy,
          cancelation,
          removeSelectedStudy,
          setCheckedPrint,
          setNewStatus
        );

        let estatus = 0;
        if (currentStudy.estatusId === status.requestStudy.solicitado) {
          estatus = status.requestStudy.capturado;
        }
        if (currentStudy.estatusId === status.requestStudy.capturado) {
          estatus = esCancelacion
            ? status.requestStudy.solicitado
            : status.requestStudy.validado;
        }
        if (currentStudy.estatusId === status.requestStudy.validado) {
          estatus = esCancelacion
            ? status.requestStudy.capturado
            : status.requestStudy.liberado;
        }
        if (currentStudy.estatusId === status.requestStudy.liberado) {
          estatus = esCancelacion
            ? status.requestStudy.validado
            : status.requestStudy.enviado;
        }

        const studyIndex = allStudies.findIndex((o) => o.id == currentStudy.id);
        if (studyIndex > -1) {
          allStudies[studyIndex].estatusId = estatus ?? 0;

          if (estatus == status.requestStudy.capturado) {
            allStudies[studyIndex].capturado = true;
            allStudies[studyIndex].sucursalCaptura = activeBranch;
          }
        }
        const saveResults = await onFinish(
          form.getFieldsValue(),
          enviarManual ?? false
        );

        if (isUpdated && saveResults) {
          loadInit();
          setObservationsSelected([]);
          // await loadInit();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setUpdating(false);
      }
    };

    const selectInputOptions = (
      tipoValor: ITipoValorForm[] | undefined,
      fieldValue: IClinicResultCaptureForm
    ) => {
      if (tipoValor === undefined) return [];

      return fieldValue.tipoValores!.map((x) => ({
        key: uuid(),
        value:
          fieldValue.tipoValorId === parameters.valueType.opcionMultiple
            ? x.opcion!
            : fieldValue.tipoValorId === parameters.valueType.unaColumna &&
              currentStudy.id !== 631
            ? x.primeraColumna
            : x.opcion!,
        label:
          fieldValue.tipoValorId === parameters.valueType.opcionMultiple
            ? x.opcion!
            : fieldValue.tipoValorId === parameters.valueType.unaColumna &&
              currentStudy.id !== 631
            ? x.primeraColumna
            : x.opcion!,
      }));
    };

    const openTextTypeModal = async (
      fieldValue: IClinicResultCaptureForm,
      field: FormListFieldData
    ) => {
      const modal: any = await ObservationModal(
        fieldValue.parametroId,
        fieldValue.tipoValorId,
        observationsSelected,
        form.getFieldValue(["parametros", field.name, "observacionesId"])
      );
      if (modal) {
        form.setFieldValue(["parametros", field.name, "resultado"], modal.data);
        form.setFieldValue(
          ["parametros", field.name, "observacionesId"],
          modal.value
        );
        setModalValues(modal);
      }
    };

    const send = () => {
      onSubmit(false, currentStudy, true);
    };
    function checkLogicComparision(result: string, logic: string) {
      if (!result || !logic) {
        return false;
      }
      if (!logic.includes("<") && !logic.includes(">")) {
        return false;
      }
      let operator = logic.toLocaleLowerCase().includes("<") ? "<" : ">";
      let number = parseFloat(
        logic?.replace(operator, "")?.replaceAll(" ", "")?.replaceAll(",", "")
      );
      let resultNumber = parseFloat(
        result?.replaceAll(" ", "")?.replaceAll(",", "") ?? "0"
      );
      return !(operator === "<"
        ? resultNumber < number
        : resultNumber > number);
    }
    useImperativeHandle(ref, () => ({
      send,
    }));

    const showHistoryCaptureParameter = (
      paciente: IProceedingForm,
      fieldValue: any,
      currentStudy: any,
      solicitud: any,
      isAllHistory: boolean = false
    ) => {
      const captureInformation: ParameterHistoryInfo = {
        paciente: paciente.nombre + " " + paciente.apellido,
        solicitud: solicitud.clave!,
        claveParametro: fieldValue.clave,
        nombreParametro: fieldValue.nombre,
        solicitudId: solicitud.solicitudId!,
        parametroId: fieldValue.parametroId!,
        estudioId: currentStudy.estudioId!,
        valorInicial: fieldValue.valorInicial,
        valorFinal: fieldValue.valorFinal,
        isAllHistory: isAllHistory,
        expediente: paciente.expediente,
        expedienteId: solicitud.expedienteId!,
      };
      openModal({
        title: isAllHistory
          ? "HISTÓRICO DE RESULTADOS"
          : `HISTORIAL DE CAPTURA`,
        body: (
          <ClinicalResultsHistory
            parameterHistory={captureInformation}
          ></ClinicalResultsHistory>
        ),
        width: isAllHistory ? 1200 : 800,
      });
    };

    return (
      <Fragment key={estudio.id}>
        <Spin spinning={loading}>
          <Row gutter={[24, 24]} className="study-divider">
            <Col span={24}>
              <StudyActions
                currentStudy={currentStudy}
                setEnvioManual={setEnvioManual}
                setCheckedPrint={setCheckedPrint}
                checkedPrint={checkedPrint}
                exportGlucoseData={exportGlucoseData}
                isMarked={isMarked}
                submitResults={onSubmit}
                tipoEstudio={"LABORATORY"}
                isXRay={false}
                updating={updating}
                formRef={form}
              />
            </Col>
          </Row>
          {currentStudy.estatusId !== status.requestStudy.cancelado ? (
            <Fragment>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <StatusTable currentStudy={currentStudy} />
                </Col>
              </Row>
            </Fragment>
          ) : (
            ""
          )}
          <Card className="capture-details">
            <Form<IClinicResultCaptureForm>
              form={form}
              // onFinish={onFinish}
              name="dynamic_form_item"
              disabled={
                currentStudy.estatusId > status.requestStudy.solicitado ||
                currentStudy.estatusId < status.requestStudy.solicitado
              }
            >
              <Row>
                <Col span={24}>
                  {/* <TitleColumns /> */}
                  <Form.List name="parametros">
                    {(fields) => (
                      <>
                        {fields.map((field) => {
                          let fieldValue = form.getFieldValue([
                            "parametros",
                            field.name,
                          ]) as IClinicResultCaptureForm;

                          // console.log("fieldValue", toJS(fieldValue));
                          let fieldResult = resultValue?.find(
                            (x) => x.id === fieldValue.id
                          )?.resultado as string;
                          console.log("-------------",fieldResult);
                          let valorMaximo = fieldValue.criticoMaximo;
                          let valorMinimo = fieldValue.criticoMinimo;
                          // console.log("Minimo " + valorMinimo);

                          let numberValue = fieldResult;
                          let fieldRange =
                            !fieldValue.valorFinal && !!fieldValue.valorInicial
                              ? checkLogicComparision(
                                  fieldResult,
                                  fieldValue.valorInicial
                                )
                              : parseFloat(fieldValue.valorInicial) >
                                  parseFloat(numberValue ?? 0) ||
                                parseFloat(numberValue ?? 0) >
                                  parseFloat(fieldValue.valorFinal);

                          switch (fieldValue.tipoValorId) {
                            case "11":
                            case "12":
                            case "13":
                            case "14":
                              break;
                            default:
                              break;
                          }
                          return (
                            <Row
                              key={fieldValue.id}
                              style={{
                                textAlign: "center",
                                marginBottom: 4,
                                justifyContent: "space-between",
                              }}
                              align="middle"
                            >
                              {fieldValue.tipoValorId ===
                                parameters.valueType.etiqueta ||
                              (fieldValue.clave === "_OB_CTG" &&
                                paciente.sexo === "M") ? (
                                fieldValue.nombre ? (
                                  <Col span={4}>
                                    <strong>{fieldValue.nombre}</strong>
                                  </Col>
                                ) : (
                                  <Col span={24}>
                                    <strong
                                      style={{
                                        color: "white",
                                        opacity: 0.1,
                                      }}
                                    >
                                      BR
                                    </strong>
                                  </Col>
                                )
                              ) : (
                                <>
                                  <Col span={4}>
                                    <h4 style={{ marginBottom: 0 }}>
                                      {fieldValue.nombre}
                                    </h4>
                                  </Col>
                                  <Col span={4}>
                                    {fieldValue.tipoValorId ===
                                      parameters.valueType.observacion ||
                                    fieldValue.tipoValorId ===
                                      parameters.valueType.parrafo ||
                                    fieldValue.tipoValorId ===
                                      parameters.valueType.texto ? (
                                      <div style={{ padding: "0 10%" }}>
                                        <Row gutter={0}>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <Col
                                              flex={1}
                                              // style={
                                              //   fieldRange && fieldResult
                                              //     ? { border: "1px solid red" }
                                              //     : {}
                                              // }
                                              className={`${
                                                fieldRange && fieldResult ? "text-red" : ""
                                              }`}
                                            >
                                              <Form.Item
                                                {...field}
                                                name={[field.name, "resultado"]}
                                                validateTrigger={[
                                                  "onChange",
                                                  "onBlur",
                                                ]}
                                                noStyle
                                              >
                                                <TextArea
                                                  placeholder="Resultado"
                                                  className="result-textarea"
                                                  rows={4}
                                                  bordered
                                                  allowClear
                                                  autoSize
                                                />
                                                {/* <Button
                                                type="primary"
                                                onClick={() => {
                                                  openTextTypeModal(
                                                    fieldValue,
                                                    field
                                                  );
                                                }}
                                                style={{ marginTop: "4px" }}
                                              >
                                                ...
                                              </Button> */}
                                              </Form.Item>
                                              {/* <Button
                                              icon={<EyeOutlined></EyeOutlined>}
                                            ></Button> */}
                                              <Form.Item
                                                {...field}
                                                name={[
                                                  field.name,
                                                  "observacionesId",
                                                ]}
                                                noStyle
                                                key={uuid()}
                                              >
                                                <Input
                                                  style={{ display: "none" }}
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col>
                                              <Button
                                                type="primary"
                                                onClick={() => {
                                                  openTextTypeModal(
                                                    fieldValue,
                                                    field
                                                  );
                                                }}
                                              >
                                                ...
                                              </Button>
                                            </Col>
                                            <Col>
                                              {currentStudy.departamentoId !==
                                                3 &&
                                                fieldValue.hasHistory && (
                                                  <Button
                                                    type="text"
                                                    onClick={() => {
                                                      showHistoryCaptureParameter(
                                                        paciente,
                                                        fieldValue,
                                                        currentStudy,
                                                        solicitud
                                                      );
                                                    }}
                                                    disabled={false}
                                                    icon={
                                                      <EyeOutlined></EyeOutlined>
                                                    }
                                                  ></Button>
                                                )}
                                            </Col>
                                          </div>
                                        </Row>
                                      </div>
                                    ) : fieldValue.tipoValorId ===
                                        parameters.valueType.numerico ||
                                      fieldValue.tipoValorId ===
                                        parameters.valueType.numericoSexo ||
                                      fieldValue.tipoValorId ===
                                        parameters.valueType.numericoEdad ||
                                      fieldValue.tipoValorId ===
                                        parameters.valueType
                                          .numericoEdadSexo ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "resultado"]}
                                            validateTrigger={[
                                              "onChange",
                                              "onBlur",
                                            ]}
                                            // noStyle
                                            key={"resultado"}
                                            rules={
                                              !fieldValue.editable ? [] : []
                                              // : numberRules
                                            }
                                            style={{ marginBottom: 0 }}
                                          >
                                            <Input
                                              placeholder="Resultado"
                                              className={`center-input ${
                                                fieldRange && fieldResult
                                                  ? "text-red"
                                                  : ""
                                              }`}
                                              style={{
                                                width: "80%",
                                                borderColor:
                                                  fieldResult &&
                                                  valorMaximo !== undefined &&
                                                  valorMinimo !== undefined &&
                                                  (parseFloat(fieldResult) >= valorMinimo && parseFloat(fieldResult) <= valorMaximo)
                                                    ? "red"
                                                    : undefined,
                                              }}
                                              allowClear
                                              disabled={
                                                !fieldValue.editable ||
                                                currentStudy.estatusId >
                                                  status.requestStudy
                                                    .solicitado ||
                                                currentStudy.estatusId <
                                                  status.requestStudy.solicitado
                                              }
                                            />                                            
                                          </Form.Item>
                                          {currentStudy.departamentoId !== 3 &&
                                            fieldValue.hasHistory && (
                                              <Button
                                                type="text"
                                                onClick={() => {
                                                  showHistoryCaptureParameter(
                                                    paciente,
                                                    fieldValue,
                                                    currentStudy,
                                                    solicitud
                                                  );
                                                }}
                                                disabled={false}
                                                icon={
                                                  <EyeOutlined></EyeOutlined>
                                                }
                                              ></Button>
                                            )}
                                        </div>
                                      </>
                                    ) : fieldValue.tipoValorId ===
                                      parameters.valueType.opcionMultiple ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "0",
                                          margin: "0",
                                        }}
                                      >
                                        {/* <Form.Item
                                          {...field}
                                          name={[field.name, "resultado"]}
                                          validateTrigger={[
                                            "onChange",
                                            "onBlur",
                                          ]}
                                          noStyle
                                          key={"resultado"}
                                        >
                                          <AutoComplete
                                            options={selectInputOptions(
                                              fieldValue.tipoValores,
                                              fieldValue
                                            )}
                                            allowClear
                                            style={{
                                              width: "80%",
                                            }}
                                            filterOption={filterOptions}
                                          />
                                        </Form.Item> */}
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "resultado"]}
                                          validateTrigger={[
                                            "onChange",
                                            "onBlur",
                                          ]}
                                          noStyle
                                          key={"resultado"}
                                        >
                                          <Select
                                            showSearch
                                            style={{ width: "80%" }}
                                            placeholder="Selecciona una opción"
                                          >
                                            {selectInputOptions(
                                              fieldValue.tipoValores,
                                              fieldValue
                                            ).map((option) => (
                                              <Select.Option
                                                key={option.key}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                        {currentStudy.departamentoId !== 3 &&
                                          fieldValue.hasHistory && (
                                            <Button
                                              type="text"
                                              onClick={() => {
                                                showHistoryCaptureParameter(
                                                  paciente,
                                                  fieldValue,
                                                  currentStudy,
                                                  solicitud
                                                );
                                              }}
                                              disabled={false}
                                              icon={<EyeOutlined></EyeOutlined>}
                                              tabIndex={-1}
                                            ></Button>
                                          )}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "0",
                                          margin: "0",
                                        }}
                                      >
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "resultado"]}
                                          validateTrigger={[
                                            "onChange",
                                            "onBlur",
                                          ]}
                                          noStyle
                                          key={"resultado"}
                                        >
                                          <Input
                                            placeholder="Resultado"
                                            className={"center-input"}
                                            style={
                                              fieldRange && fieldResult
                                                ? {
                                                    width: "80%",
                                                    borderColor: "red",
                                                  }
                                                : {
                                                    width: "80%",
                                                  }
                                            }
                                            allowClear
                                            disabled={
                                              !fieldValue.editable ||
                                              currentStudy.estatusId >
                                                status.requestStudy
                                                  .solicitado ||
                                              currentStudy.estatusId <
                                                status.requestStudy.solicitado
                                            }
                                          />
                                        </Form.Item>
                                        {currentStudy.departamentoId !== 3 &&
                                          fieldValue.hasHistory && (
                                            <Button
                                              type="text"
                                              onClick={() => {
                                                showHistoryCaptureParameter(
                                                  paciente,
                                                  fieldValue,
                                                  currentStudy,
                                                  solicitud
                                                );
                                              }}
                                              disabled={false}
                                              icon={<EyeOutlined></EyeOutlined>}
                                              tabIndex={-1}
                                            ></Button>
                                          )}
                                      </div>
                                    )}
                                  </Col>
                                  <Col span={4}>
                                    {fieldValue.unidadNombre == null
                                      ? "-"
                                      : fieldValue.unidadNombre}
                                  </Col>
                                  <Col span={4}>
                                    {fieldValue.valorInicial == null
                                      ? "-"
                                      : referenceValues(
                                          fieldValue.tipoValorId,
                                          fieldValue.valorInicial,
                                          fieldValue.valorFinal
                                        )}
                                  </Col>
                                  <Col span={4}>
                                    {fieldValue.tipoValorId !==
                                      parameters.valueType.observacion && (
                                      <Button
                                        type="text"
                                        onClick={() => {
                                          showHistoryCaptureParameter(
                                            paciente,
                                            fieldValue,
                                            currentStudy,
                                            solicitud,
                                            true
                                          );
                                        }}
                                        disabled={false}
                                        icon={<FolderFilled></FolderFilled>}
                                        tabIndex={-1}
                                      ></Button>
                                    )}

                                    {/* {fieldValue.ultimoResultado == null &&
                                    fieldValue.ultimaSolicitud == null ? (
                                      "-"
                                    ) : (
                                      <Tooltip
                                        title={
                                          <>
                                            <Text>
                                              {fieldValue.ultimoResultado} -{" "}
                                              <Link
                                                onClick={() => {
                                                  navigate(
                                                    `/clinicResultsDetails/${fieldValue.ultimoExpedienteId}/${fieldValue.ultimaSolicitudId}`
                                                  );
                                                }}
                                              >
                                                {fieldValue.ultimaSolicitud}
                                              </Link>
                                            </Text>
                                          </>
                                        }
                                        color="#ffffff"
                                      >
                                        <EyeOutlined
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Tooltip>
                                    )} */}
                                  </Col>
                                  {columnTypes.includes(
                                    fieldValue.tipoValorId
                                  ) && (
                                    <>
                                      <Col span={7}></Col>
                                      <Col span={1}></Col>
                                      <Col span={9}>
                                        {fieldValue.tipoValores!.map((c) => (
                                          <Row
                                            key={c.id}
                                            gutter={[12, 12]}
                                            style={{ textAlign: "left" }}
                                          >
                                            {c.primeraColumna && (
                                              <Col
                                                span={
                                                  !c.segundaColumna ? 24 : 13
                                                }
                                              >
                                                {c.primeraColumna}
                                              </Col>
                                            )}
                                            {c.segundaColumna && (
                                              <Col
                                                span={
                                                  !c.terceraColumna ? 11 : 5
                                                }
                                                style={{
                                                  textAlign: c.terceraColumna
                                                    ? "right"
                                                    : "center",
                                                }}
                                              >
                                                {c.segundaColumna}
                                              </Col>
                                            )}
                                            {c.terceraColumna && (
                                              <>
                                                <Col
                                                  span={1}
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  -
                                                </Col>
                                                <Col
                                                  span={5}
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {c.terceraColumna}
                                                </Col>
                                              </>
                                            )}
                                            {c.cuartaColumna && (
                                              <Col flex={1}>
                                                {c.cuartaColumna}
                                              </Col>
                                            )}
                                            {c.quintaColumna && (
                                              <Col flex={1}>
                                                {c.quintaColumna}
                                              </Col>
                                            )}
                                          </Row>
                                        ))}
                                      </Col>
                                      <Col span={4}></Col>
                                    </>
                                  )}
                                </>
                              )}
                            </Row>
                          );
                        })}
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Form>
          </Card>
        </Spin>
      </Fragment>
    );
  }
);
export default observer(ClinicalResultsDetail);
